/* Remove browser defaults */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Nanum Gothic', sans-serif;
  }

  
  /* Style App.js wrapper */
  .App {
    width: 100vw;
    height: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* font-family: 'Nanum Gothic', sans-serif; */
  }
  
  .body {
    background: rgb(42,100,213);
    background: linear-gradient(105deg, rgba(42,100,213,1) 0%, rgba(2,8,89,1) 94%);
  }

  /* Tab Container */
  .Tabs {
    width: 100%;
    height: auto;
    min-height: 400px;
    background: #053742;
    margin: 3.5rem auto 1.5rem;
    padding: 2rem 1rem;
    color: #E8F0F2;
    border-radius: 2rem;
  }
   
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }
   
  .react-tabs__tab-list {
    width: 60%;
    margin: 0 auto 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #39A2DB;
    border-radius: 2rem;
    padding-left: 0px;
  }
   
  .react-tabs__tab {
    width: 50%;
    padding: 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.7s;
  }
   
  .react-tabs__tab--selected {
    background: #39A2DB;
  }
   
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
   
  .react-tabs__tab:hover {
    background: rgba(50, 224, 196, 0.15);
  }
   
  .react-tabs__tab:first-child {
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }
   
  .react-tabs__tab:last-child {
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }
   
  .react-tabs__tab-panel {
    display: none;
  }
   
  .react-tabs__tab-panel--selected {
    display: block;
    font-size: 2rem;
    text-align: center;
  }